var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formCompany"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Customer Source","vid":"Customer Source"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Customer Source","label-for":"h-First"}},[_c('b-form-select',{attrs:{"options":_vm.socialMedias},model:{value:(_vm.company.source),callback:function ($$v) {_vm.$set(_vm.company, "source", $$v)},expression:"company.source"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Sales Person","vid":"Sales Person"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sales Person","label-for":"h-First"}},[_c('b-form-select',{attrs:{"options":_vm.optionsSalesPerson},model:{value:(_vm.company.salesPersonId),callback:function ($$v) {_vm.$set(_vm.company, "salesPersonId", $$v)},expression:"company.salesPersonId"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Company Name","label-for":"h-company"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Company Name","vid":"Company Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-full","placeholder":"Company Name"},model:{value:(_vm.company.otherName),callback:function ($$v) {_vm.$set(_vm.company, "otherName", $$v)},expression:"company.otherName"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"d-email"}},[_c('b-form-input',{attrs:{"id":"d-email","type":"email","placeholder":"Email"},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Address","vid":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Address","label-for":"h-Address"}},[_c('b-form-input',{attrs:{"id":"h-Address","placeholder":"Address"},model:{value:(_vm.company.addressLine1),callback:function ($$v) {_vm.$set(_vm.company, "addressLine1", $$v)},expression:"company.addressLine1"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Street Name","vid":"Street Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Street Name","label-for":"h-Street"}},[_c('b-form-input',{attrs:{"id":"h-Street","placeholder":"Street Name"},model:{value:(_vm.company.addressLine2),callback:function ($$v) {_vm.$set(_vm.company, "addressLine2", $$v)},expression:"company.addressLine2"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"City","vid":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"City","label-for":"h-City"}},[_c('b-form-input',{attrs:{"id":"h-City","placeholder":"City"},model:{value:(_vm.company.city),callback:function ($$v) {_vm.$set(_vm.company, "city", $$v)},expression:"company.city"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Company Tel","vid":"Company Tel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Tel","label-for":"h-tel"}},[_c('b-form-input',{attrs:{"id":"h-tel","placeholder":"Company Tel","type":"number","onkeydown":"javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"},model:{value:(_vm.company.tel),callback:function ($$v) {_vm.$set(_vm.company, "tel", $$v)},expression:"company.tel"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"h-Website"}},[_c('b-form-input',{attrs:{"id":"h-Website","placeholder":"Website"},model:{value:(_vm.company.website),callback:function ($$v) {_vm.$set(_vm.company, "website", $$v)},expression:"company.website"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"VAT Number","label-for":"h-Number"}},[_c('b-form-input',{attrs:{"id":"h-Number","placeholder":"VAT Number"},model:{value:(_vm.company.vatNumber),callback:function ($$v) {_vm.$set(_vm.company, "vatNumber", $$v)},expression:"company.vatNumber"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Company BRN","vid":"Company BRN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company BRN","label-for":"h-Number"}},[_c('b-form-input',{attrs:{"id":"h-Number","placeholder":"Company BRN"},model:{value:(_vm.company.brn),callback:function ($$v) {_vm.$set(_vm.company, "brn", $$v)},expression:"company.brn"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Products interested in","label-for":"h-title"}},[_c('b-form-textarea',{attrs:{"id":"event-description","placeholder":"Products interested in"},model:{value:(_vm.company.comments),callback:function ($$v) {_vm.$set(_vm.company, "comments", $$v)},expression:"company.comments"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
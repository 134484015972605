<template>
  <validation-observer ref="formCompany">
    <b-form @submit.prevent>
      <b-row>
        <!-- username -->
        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Customer Source"
            vid="Customer Source"
          >
            <b-form-group label="Customer Source" label-for="h-First">
              <b-form-select v-model="company.source" :options="socialMedias" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            rules="required"
            #default="{ errors }"
            name="Sales Person"
            vid="Sales Person"
          >
            <b-form-group label="Sales Person" label-for="h-First">
              <b-form-select
                v-model="company.salesPersonId"
                :options="optionsSalesPerson"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Company Name" label-for="h-company">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Company Name"
              vid="Company Name"
            >
              <b-form-input
                id="h-full"
                placeholder="Company Name"
                v-model="company.otherName"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- email -->
        <b-col cols="12">
          <validation-provider
            rules="required|email"
            v-slot="{ errors }"
            name="email"
            vid="email"
          >
            <b-form-group label="Email" label-for="d-email">
              <b-form-input
                id="d-email"
                type="email"
                placeholder="Email"
                v-model="company.email"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Address"
            vid="Address"
          >
            <b-form-group label="Address" label-for="h-Address">
              <b-form-input
                id="h-Address"
                placeholder="Address"
                v-model="company.addressLine1"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Street Name"
            vid="Street Name"
          >
            <b-form-group label="Street Name" label-for="h-Street">
              <b-form-input
                id="h-Street"
                placeholder="Street Name"
                v-model="company.addressLine2"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="City"
            vid="City"
          >
            <b-form-group label="City" label-for="h-City">
              <b-form-input id="h-City" placeholder="City" v-model="company.city" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Company Tel"
            vid="Company Tel"
          >
            <b-form-group label="Company Tel" label-for="h-tel">
              <b-form-input id="h-tel" placeholder="Company Tel" type="number"  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="company.tel" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Website" label-for="h-Website">
            <b-form-input
              id="h-Website"
              placeholder="Website"
              v-model="company.website"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="VAT Number" label-for="h-Number">
            <b-form-input
              id="h-Number"
              placeholder="VAT Number"
              v-model="company.vatNumber"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Company BRN"
            vid="Company BRN"
          >
            <b-form-group label="Company BRN" label-for="h-Number">
              <b-form-input
                id="h-Number"
                placeholder="Company BRN"
                v-model="company.brn"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Products interested in" label-for="h-title">
            <b-form-textarea
              id="event-description"
              placeholder="Products interested in"
              v-model="company.comments"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BDropdownItem,
  BFormTextarea,
  BDropdown,
  BFormDatalist,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BDropdownItem,
    BFormTextarea,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsSalesPerson: [],
      
      socialMedias: [
        { text: "Other", value: 0 },
        { text: "Facebook", value: 1 },
        { text: "Instagram", value: 2 },
        { text: "Twitter", value: 3 },
        { text: "LinkedIn", value: 4 },
      ],
      selected: "Select Social Media",

      ////////
      required,
      email,
    };
  },
  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },

  resetForm(){
    this.$refs.formCompany.reset();
  },

  async mounted() {
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
  },
  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    saveSocialMedia(value, text) {
      this.company.source = value;
      this.selected = text;
    },
    async submitForm() {
      this.company.companyName = this.company.otherName;
      let isValid = await this.$refs.formCompany.validate();
      return isValid;
    },
  },

  watch: {
    company: {
      handler(newVal) {

      }
    }
  },
};
</script>

<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div v-if="!loadingSpinner">
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- accoint details tab -->

        <tab-content title="Customer Details">
          <b-tabs pills v-on:input="getValue($event)">
            <b-tab title="Individual" active>
              <b-card-text>
                <b-card title="Add Individual">
                  <AddIndividual :individual="individual" ref="addCustomerIndividual"/>
                </b-card>
              </b-card-text>
            </b-tab>
            <b-tab title="Company">
              <b-card-text>
                <b-card title="Add Company">
                  <AddCompany :company="company" ref="addCustomerCompany" />
                </b-card>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BTab,
  BCard,
  BCardText,
  BContainer,
  BSpinner,
  BTabs,
} from "bootstrap-vue";
import AddCompany from "./AddCompany.vue";
import AddIndividual from "./AddIndividual.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BCardText,
    TabContent,
    BRow,
    BContainer,
    BCol,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    AddCompany,
    AddIndividual,
    BTab,
    BCard,
    BTabs,
  },
  data() {
    return {
      loadingSpinner: true,
      individual: {
        source: null,
        lName: null,
        fName: null,
        email: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        title: null,
        tel: null,
        jobTitle: null,
        comments: null,
        salesPersonId: null,
      },

      company: {
        source: null,
        companyName: null,
        otherName: null,
        email: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        tel: null,
        website: null,
        vatNumber: null,
        comments: null,
        salesPersonId: null,
        brn: null,
      },

      valueCheck: null,
    };
  },
  computed: {
    ...mapGetters("customerModule", {
      loading: "loading",
    }),
    otherName() {
      return this.individual.fName + " " + this.individual.lName;
    },
  },

  mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);
  },
  methods: {
    ////////
    ...mapActions("customerModule", ["addCustomersAction"]),

    getValue(value) {
      this.valueCheck = value;
    },

    async formSubmitted() {
      if (this.valueCheck == 0){
      await this.addLeadsIndividual();
    }else{
      await this.addLeadsCompany();
    }
    },


    success(response) {
      this.$swal({
        title: "Customer added!",
        text: "You have successfully added the Customer!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$router.push({ name: "customer-list" });
    },

    // error
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    async addLeadsIndividual() {
      let isValid = await this.$refs.addCustomerIndividual.submitForm();

      if(isValid){
      let payload = {
        otherName: this.otherName,
        email: this.individual.email,
        addressLine1: this.individual.addressLine1,
        addressLine2: this.individual.addressLine2,
        city: this.individual.city,
        tel: this.individual.tel,
        jobTitle: this.individual.jobTitle,
        source: this.individual.source,
        comments: this.individual.comments,
        title: this.individual.title,
        type: 0,
        status: 0,
        salesPersonId: this.individual.salesPersonId,
      };
      this.addCustomersAction(payload)
        .then((response) => {
          this.success(response);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          }); 
        })
        .catch(() => {
          this.error();
        });
      }
    },

    async addLeadsCompany() {
      let isValid = await this.$refs.addCustomerCompany.submitForm();

      if(isValid){
      let payload = {
        otherName: this.company.otherName,
        email: this.company.email,
        companyName: this.company.companyName,
        addressLine1: this.company.addressLine1,
        addressLine2: this.company.addressLine2,
        city: this.company.city,
        tel: this.company.tel,
        website: this.company.website,
        vatNumber: this.company.vatNumber,
        source: this.company.source,
        comments: this.company.comments,
        type: 1,
        status: 0,
        isActive: true,
        brn: this.company.brn,
        salesPersonId: this.company.salesPersonId,
      };
      this.addCustomersAction(payload)
        .then((response) => {
          this.success(response);

          this.$toast({
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.error();
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.dropdown-toggle {
  text-align: left;
}
</style>

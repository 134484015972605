<template>
  <validation-observer ref="formIndividual">
    <b-form @submit.prevent>
      <b-row>
        <!-- username -->
        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Customer Source"
            vid="Customer Source"
          >
            <b-form-group label="Customer Source" label-for="h-First">
              <b-form-select v-model="individual.source" :options="socialMedias" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Sales Person"
            vid="Sales Person"
          >
            <b-form-group label="Sales Person" label-for="h-First">
              <b-form-select
                v-model="individual.salesPersonId"
                :options="optionsSalesPerson"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Title"
            vid="Title"
          >
            <b-form-group label="Title" label-for="h-First">
              <b-form-select v-model="individual.title" :options="options" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="First Name" label-for="h-First">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="First Name"
              vid="First Name"
            >
              <b-form-input
                id="h-First"
                placeholder="First Name"
                v-model="individual.fName"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Last Name" label-for="h-Last">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Last Name"
              vid="Last Name"
            >
              <b-form-input
                id="h-Last"
                placeholder="Last Name"
                v-model="individual.lName"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- email -->
        <b-col cols="12">
          <validation-provider
            rules="required|email"
            v-slot="{ errors }"
            name="Email"
            vid="Email"
          >
            <b-form-group label="Email" label-for="d-email">
              <b-form-input
                id="d-email"
                type="email"
                placeholder="Email"
                v-model="individual.email"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Address"
            vid="Address"
          >
            <b-form-group label="Address" label-for="h-Address">
              <b-form-input
                id="h-Address"
                placeholder="Address"
                v-model="individual.addressLine1"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Street Name"
            vid="Street Name"
          >
            <b-form-group label="Street Name" label-for="h-Street">
              <b-form-input
                id="h-Street"
                placeholder="Street Name"
                v-model="individual.addressLine2"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="City"
            vid="City"
          >
            <b-form-group label="City" label-for="h-City">
              <b-form-input id="h-City" placeholder="City" v-model="individual.city" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
                             
        <b-col cols="12">
          <validation-provider rules="required" v-slot="{ errors }" name="Tel" vid="Tel">
            <b-form-group label="Tel" label-for="h-tel">
              <b-form-input id="h-tel" placeholder="Tel" type="number"  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"       v-model="individual.tel"/>

              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Job Title"
            vid="Job Title"
          >
            <b-form-group label="Job Title" label-for="h-title">
              <b-form-input
                id="h-title"
                placeholder="Job title"
                v-model="individual.jobTitle"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Products Interested in" label-for="h-title">
            <b-form-textarea
              id="event-description"
              placeholder="Products Interested in"
              v-model="individual.comments"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormDatalist,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    individual: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {
      optionsSalesPerson: [],

      options: [
        { value: null, text: "Please select a title" },
        { value: "Mr", text: "Mr" },
        { value: "Mrs", text: "Mrs" },
        { value: "Ms", text: "Ms" },
      ],
      socialMedias: [
        { text: "Other", value: 0 },
        { text: "Facebook", value: 1 },
        { text: "Instagram", value: 2 },
        { text: "Twitter", value: 3 },
        { text: "LinkedIn", value: 4 },
      ],
      selected: "Select Social Media",
      ///////////////
      required,
      email,
    };
  },
  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },
  async mounted() {
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
  },
  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    saveSocialMedia(value, text) {
      this.individual.source = value;
      this.selected = text;
    },

    resetForm() {
      // Your form submission
      this.$refs.formIndividual.reset(); // This will clear that form
    },
    async submitForm() {
      let isValid = await this.$refs.formIndividual.validate();
      return isValid;
    },

 

  },
  watch: {
    individual: {
      handler(newVal) {

      }
    }
  },
};
</script>
